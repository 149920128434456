<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Colaboradores / <strong class="text-primary small">{{model.nombre}} {{model.apellido_paterno}} {{model.apellido_materno}}</strong></h3>
                </div>
                <div class="nk-block-head-content">
                  <router-link to="/sistema/empleados" replace class="btn btn-outline-light bg-white d-none d-sm-inline-flex"><em class="icon ni ni-arrow-left"></em><span>Regresar</span></router-link>
                  <router-link to="/sistema/empleados" replace class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em class="icon ni ni-arrow-left"></em></router-link>
                </div>
              </div>
            </div><!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                      <li class="nav-item" v-if="(tabs.tabs.includes('1'))">
                        <a class="nav-link" href="#" :class="{active: section===''}" @click.prevent="showSection('')"><em class="icon ni ni-user-circle"></em><span>Información Personal</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('2'))">
                        <a class="nav-link" href="#" :class="{active: section==='documentacion'}" @click.prevent="showSection('documentacion')"><em class="icon ni ni-article"></em><span>Documentación</span></a>
                      </li>
                      <li class="nav-item" v-if="isAdmin && (tabs.tabs.includes('3'))">
                        <a class="nav-link" href="#" :class="{active: section==='contratos'}" @click.prevent="showSection('contratos')"><em class="icon ni ni-edit-alt"></em><span>Contratos</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('4'))">
                        <a class="nav-link" href="#" :class="{active: section==='medico'}" @click.prevent="showSection('medico')"><em class="icon ni ni-plus-medi"></em><span>Médico</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('5'))">
                        <a class="nav-link" href="#" :class="{active: section==='tallas'}" @click.prevent="showSection('tallas')"><em class="icon ni ni-tags"></em><span>Tallas Uniformes</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('6'))">
                        <a class="nav-link" href="#" :class="{active: section==='entrega_uniformes'}" @click.prevent="showSection('entrega_uniformes')"><em class="icon ni ni-shield-star"></em><span>Entrega Uniformes/E.P.</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('7'))">
                        <a class="nav-link" href="#" :class="{active: section==='cursos'}" @click.prevent="showSection('cursos')"><em class="icon ni ni-star"></em><span>Cursos y/o Diplomados</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('8'))">
                        <a class="nav-link" href="#" :class="{active: section==='evaluaciones_desempeño'}" @click.prevent="showSection('evaluaciones_desempeño')"><em class="icon ni ni-histroy"></em><span>Ev. de Desempeño</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('9'))">
                        <a class="nav-link" href="#" :class="{active: section==='evaluaciones_uniformes'}" @click.prevent="showSection('evaluaciones_uniformes')"><em class="icon ni ni-shield-check"></em><span>Ev. Uniformes/E.P.</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('10'))">
                        <a class="nav-link" href="#" :class="{active: section==='incapacidades'}" @click.prevent="showSection('incapacidades')"><em class="icon ni ni-calender-date"></em><span>Incapacidades</span></a>
                      </li>
                      <li class="nav-item" v-if="(tabs.tabs.includes('11'))">
                        <a class="nav-link" href="#" :class="{active: section==='actas_administrativas'}" @click.prevent="showSection('actas_administrativas')"><em class="icon ni ni-alert"></em><span>Actas Administrativas</span></a>
                      </li>
                      <li class="nav-item" v-if="isAdmin && (tabs.tabs.includes('12'))">
                        <a class="nav-link" href="#" :class="{active: section==='otros'}" @click.prevent="showSection('otros')"><em class="icon ni ni-reports-alt"></em><span>Otros</span></a>
                      </li>
                      <li class="nav-item" v-if="isAdmin && (tabs.tabs.includes('13'))">
                        <a class="nav-link" href="#" :class="{active: section==='bajas'}" @click.prevent="showSection('bajas')"><em class="icon ni ni-user-cross"></em><span>Bajas</span></a>
                      </li>
                    </ul><!-- .nav-tabs -->
                    <form v-if="section === ''" @submit.prevent="save">
                      <div class="card-inner">
                        <div class="nk-block">
                          <div class="nk-block-head">
                            <h5 class="title">Información Personal</h5>
                            <p>Datos básicos como nombre, correo electrónico, etc.</p>
                          </div><!-- .nk-block-head -->
                          <div class="profile-ud-list">
                            <div class="profile-ud-item"></div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">CURP <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <input type="text" class="form-control" v-model="model.curp" :disabled="isCliente">
                                  <button v-if="!isCliente" type="button" class="btn btn-primary" @click="getInfo">Cargar</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-list">
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Nombre <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                    <input type="text" class="form-control" v-model="model.nombre" required :disabled="isCliente">
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Apellido paterno <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                    <input type="text" class="form-control" v-model="model.apellido_paterno" required :disabled="isCliente">
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Apellido materno <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                    <input type="text" class="form-control" v-model="model.apellido_materno" required :disabled="isCliente">
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Teléfono</span>
                                <div class="profile-ud-value">
                                  <span class="w-100">
                                    <input ref="input_tel" type="tel" class="form-control" v-model="model.telefono" :disabled="isCliente">
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Género <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <select class="form-control" v-model="model.genero" :disabled="isCliente">
                                    <option v-for="opt in ['Masculino','Femenino','Otro']" :key="opt" :value="opt">{{opt}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Estado Civil <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <select class="form-control" v-model="model.estado_civil" :disabled="isCliente">
                                    <option v-for="opt in ESTADOS_CIVILES" :key="opt.key" :value="opt.value">{{opt.value}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label"><span>Fecha de Nacimiento <span class="ml-1 text-danger">*</span></span></span>
                                <div class="profile-ud-value">
                                  <input type="date" class="form-control" v-model="model.fecha_nacimiento" required :disabled="isCliente">
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Estado <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <SelectEstado v-model:estado="model.id_estado" :disabled="isCliente"></SelectEstado>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Municipio <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <SelectMunicipio :estado="model.id_estado" v-model:municipio="model.id_municipio" :disabled="isCliente"></SelectMunicipio>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Servicio <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <SelectServicio class="form-control" v-model:servicio="model.id_servicio" :disabled="isCliente"></SelectServicio>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Puesto <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <input type="text" class="form-control" v-model="model.puesto" maxlength="500" required :disabled="isCliente">
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Fecha de Ingreso <span class="ml-1 text-danger">*</span></span>
                                <div class="profile-ud-value">
                                  <input type="date" class="form-control" v-model="model.fecha_ingreso" required :disabled="isCliente">
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Superior <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                  <SelectSuperior v-model:superior="model.id_superior" :disabled="isCliente"></SelectSuperior>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Contacto de Emergencia</span>
                                <div class="profile-ud-value">
                                  <textarea v-model="model.contacto_emergencia" class="form-control" maxlength="500" :disabled="isCliente"></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Observaciones</span>
                                <div class="profile-ud-value">
                                  <textarea v-model="model.observaciones" class="form-control" maxlength="5000" :disabled="isCliente"></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="profile-ud-item" v-if="isAdmin">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Observaciones Internas</span>
                                <div class="profile-ud-value">
                                  <textarea v-model="model.observaciones_internas" class="form-control" maxlength="5000" :disabled="isCliente"></textarea>
                                </div>
                              </div>
                            </div>

                          </div><!-- .profile-ud-list -->
                          <div class="w-100 text-right">
                            <button v-if="!isCliente" type="submit" class="mt-2 btn btn-lg btn-primary">Actualizar Colaborador</button>
                          </div>
                        </div><!-- .nk-block -->
                        <div class="nk-block">
                          <div class="nk-block-head nk-block-head-line">
                            <h6 class="title overline-title text-base">Información Adicional</h6>
                          </div><!-- .nk-block-head -->
                          <div class="profile-ud-list">
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">ID</span>
                                <span class="profile-ud-value">{{model.id_empleado}}</span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Estatus</span>
                                <span class="profile-ud-value">
                                  <span :class="['estatus', model.estatus]">{{model.estatus}}</span>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">{{model.historial.estatus}} por</span>
                                <span class="profile-ud-value">{{model.historial.responsable}}</span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Fecha</span>
                                <span class="profile-ud-value">{{$filters.fecha(model.historial.fecha)}}</span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Actualizado por</span>
                                <span class="profile-ud-value">{{model.creador}}</span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Fecha</span>
                                <span class="profile-ud-value">{{$filters.fecha(model.fecha_actualizacion)}}</span>
                              </div>
                            </div>
                          </div><!-- .profile-ud-list -->
                        </div><!-- .nk-block -->
                      </div><!-- .card-inner -->
                    </form>

                    <div v-if="section === 'documentacion'" class="card-inner">
                      <DocumentacionEmpleado :empleado="model"></DocumentacionEmpleado>
                    </div>

                    <div v-if="section === 'contratos'" class="card-inner">
                      <ContratosEmpleado :empleado="model"></ContratosEmpleado>
                    </div>

                    <div v-if="section === 'cursos'" class="card-inner">
                      <CursosEmpleado :empleado="model"></CursosEmpleado>
                    </div>

                    <div v-if="section === 'entrega_uniformes'" class="card-inner">
                      <EntregaUniformesEmpleado :empleado="model"></EntregaUniformesEmpleado>
                    </div>

                    <div v-if="section === 'evaluaciones_desempeño'" class="card-inner">
                      <EvaluacionesDesempenoEmpleado :empleado="model"></EvaluacionesDesempenoEmpleado>
                    </div>

                    <div v-if="section === 'evaluaciones_uniformes'" class="card-inner">
                      <EvaluacionesUniformesEmpleado :empleado="model"></EvaluacionesUniformesEmpleado>
                    </div>

                    <div v-if="section === 'tallas'" class="card-inner">
                      <TallasEmpleado :empleado="model"></TallasEmpleado>
                    </div>

                    <div v-if="section === 'medico'" class="card-inner">
                      <MedicoEmpleado :empleado="model"></MedicoEmpleado>
                    </div>

                    <div v-if="section === 'incapacidades'" class="card-inner">
                      <IncapacidadesEmpleado :empleado="model"></IncapacidadesEmpleado>
                    </div>

                    <div v-if="section === 'actas_administrativas'" class="card-inner">
                      <ActasAdministrativasEmpleado :empleado="model"></ActasAdministrativasEmpleado>
                    </div>

                    <div v-if="section === 'otros'" class="card-inner">
                      <OtrosEmpleado :empleado="model"></OtrosEmpleado>
                    </div>

                    <div v-if="section === 'bajas'" class="card-inner">
                      <BajasEmpleado :empleado="model"></BajasEmpleado>
                    </div>

                  </div><!-- .card-content -->
                </div><!-- .card-aside-wrap -->
              </div><!-- .card -->
            </div><!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import {useApi} from "@/use/useApi";
import { ref, onMounted, defineComponent, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from "sweetalert2";
import intlTelInput from 'intl-tel-input';
import moment from "moment";
import DocumentacionEmpleado from "@/views/sistema/empleados/secciones/Documentacion";
import CursosEmpleado from "@/views/sistema/empleados/secciones/Cursos";
import EvaluacionesDesempenoEmpleado from "@/views/sistema/empleados/secciones/EvaluacionesDesempeno";
import EvaluacionesUniformesEmpleado from "@/views/sistema/empleados/secciones/EvaluacionesUniformes";
import TallasEmpleado from "@/views/sistema/empleados/secciones/Tallas";
import MedicoEmpleado from "@/views/sistema/empleados/secciones/Medico";
import ActasAdministrativasEmpleado from "@/views/sistema/empleados/secciones/ActasAdministrativas";
import useAuth from "@/providers/auth";
import SelectEstado from "@/components/globales/SelectEstado";
import SelectMunicipio from "@/components/globales/SelectMunicipio";
import IncapacidadesEmpleado from "@/views/sistema/empleados/secciones/Incapacidades";
import BajasEmpleado from "@/views/sistema/empleados/secciones/Bajas";
import SelectServicio from "@/components/globales/SelectServicio";
import ContratosEmpleado from "@/views/sistema/empleados/secciones/Contratos";
import EntregaUniformesEmpleado from "@/views/sistema/empleados/secciones/EntregaUniformes";
import OtrosEmpleado from "@/views/sistema/empleados/secciones/Otros";
import SelectSuperior from "@/components/globales/SelectSuperior";
import {ESTADOS_CIVILES} from "@/providers/config";
export default defineComponent({
  name: "Editar Empleado",
  components: {
    SelectSuperior,
    OtrosEmpleado,
    EntregaUniformesEmpleado,
    ContratosEmpleado,
    SelectServicio,
    BajasEmpleado,
    IncapacidadesEmpleado,
    SelectMunicipio,
    SelectEstado,
    ActasAdministrativasEmpleado,
    MedicoEmpleado,
    TallasEmpleado,
    EvaluacionesUniformesEmpleado,
    EvaluacionesDesempenoEmpleado, CursosEmpleado, DocumentacionEmpleado, AppView},
  setup() {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();
    const {params, query} = useRoute();
    const { isAdmin, isCliente } = useAuth();

    const input_tel = ref(null);
    const section = ref('');

    const state = reactive({
      isCliente: isCliente
    });

    const model = ref({
      curp: '',
      id_empleado: 0,
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      telefono: '',
      genero: 'Masculino',
      estado_civil: 'Soltero',
      fecha_nacimiento: '',
      id_estado: '01',
      id_municipio: '001',
      puesto: '',
      fecha_ingreso: '',
      observaciones: '',
      observaciones_internas: '',
      historial: {
        estatus: '',
        responsable: ''
      }
    });

    const tabs = ref({
      tabs: ['1','2','3','4','5','6','7','8','9','10','11','12','13']
    });

    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/get', {id_empleado: params.id_empleado});
      if(data === null){
        router.push('/sistema/empleados');
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.push('/sistema/empleados');
          Swal.fire('Error', data.message, 'error');
        } else {
          model.value = data.model;
        }
      }
    }

    async function save(){
      loading.message = 'Actualizando empleado';
      //Validar telefono
      if(window.iti.isValidNumber()){
        model.value.telefono = window.iti.getNumber();
      }
      //Save
      let {data} = await make('empleados/edit', {model: model.value});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          Swal.fire('Éxito', data.message, 'success').then(() => {
            router.push('/sistema/empleados');
          });
        }
      }
    }

    async function getInfo(){
      loading.message = 'Cargando información';
      
      let {data} = await make('empleados/getInfo', {model: model.value});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          model.value.nombre = data.model.Nombre;
          model.value.apellido_paterno = data.model.PrimerApellido;
          model.value.apellido_materno = data.model.SegundoApellido;
          model.value.fecha_nacimiento = moment(new Date(data.model.FechaNac)).format("YYYY-DD-MM");
          model.value.genero = data.model.Sexo == "H" ? "Masculino" : data.model.Sexo == "M" ? "Femenino" : "Otro";
        }
      }
    }

    async function getTabs(){
      loading.message = 'Cargando información';
      
      let {data} = await make('empleados/getTabs', {model: model.value});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          tabs.value.tabs = data.user.secciones;
        }
      }
    }

    function showSection(_section){
      section.value = _section;
    }

    //Mounted
    onMounted(() => {
      get();

      if (state.isCliente) {
        getTabs();  
      }
      
      window.iti = intlTelInput(input_tel.value, {
        initialCountry: 'mx',
        onlyCountries: ['us','mx'],
        nationalMode: true,
        utilsScript: require('intl-tel-input/build/js/utils.js')
      });


      if(query.baja){
        showSection('bajas');
      }

    });

    return {model, tabs, input_tel, loading, section, isAdmin, isCliente, save, showSection, getInfo, ESTADOS_CIVILES};
  }
});
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}
</style>