<template>
  <form @submit.prevent="save">
    <div class="nk-block">
      <div class="nk-block-head d-flex justify-between">
        <h5 class="title">Bajas</h5>
        <button v-if="model.estatus === 'Baja'" class="btn btn-outline-danger" @click.prevent="remove">Eliminar Empleado</button>
      </div><!-- .nk-block-head -->
      <div v-if="!bajas.length" class="alert alert-info">
        <p>El empleado no cuenta con historial de bajas.</p>
      </div>
      <div v-if="bajas.length">
        <div class="row mb-3" v-for="_baja in bajas" :key="_baja.id_baja">
          <div class="card card-bordered col-12" :class="{'border-success': baja.id_baja === _baja.id_baja}">
            <div class="card-body d-flex justify-between text-uppercase flex-column flex-md-row">
              <div class="d-flex flex-column">
                <p><strong>MOTIVO:</strong> {{_baja.motivo}}</p>
                <p><strong>FECHA:</strong> {{$filters.fecha(_baja.fecha, 'DD/MM/YYYY')}}</p>
              </div>
              <div class="d-flex flex-column">
                <p><strong>FINIQUITO:</strong> <a :href="_baja.f_finiquito?.url" target="_blank">{{_baja.f_finiquito?.nombre}}</a></p>
                <p><strong>FINIQUITO FIRMADO:</strong> <a :href="_baja.f_finiquito_firmado?.url" target="_blank">{{_baja.f_finiquito_firmado?.nombre}}</a></p>
              </div>
              <div class="d-flex flex-column">
                <p><strong>OBSERVACIONES:</strong> {{_baja.observaciones}}</p>
                <p><strong>OBSERVACIONES INTERNAS:</strong> {{_baja.observaciones_internas}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="newBaja" v-if="model.estatus === 'Baja' || nueva_baja" class="mt-3">
        <h5>Detalles de la baja</h5>
        <div class="row mt-4">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="form-label">Motivo <span class="ml-1 text-danger">*</span></label>
              <input type="text" class="form-control" v-model.lazy="baja.motivo" required>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="form-label">Fecha <span class="ml-1 text-danger">*</span></label>
              <input type="date" class="form-control" v-model.lazy="baja.fecha" required>
            </div>
          </div>
          <div class="col-sm-6 mt-3">
            <div class="form-group">
              <label class="form-label">Finiquito <span class="ml-1 text-danger">*</span></label>
              <Uploader :preFiles="$filters.getFiles(baja.f_finiquito?.id_archivo)" :server="$filters.getServer(model)" v-model:file="baja.f_finiquito"></Uploader>
            </div>
          </div>
          <div class="col-sm-6 mt-3">
            <div class="form-group">
              <label class="form-label">Finiquito Firmado</label>
              <Uploader :preFiles="$filters.getFiles(baja.f_finiquito_firmado?.id_archivo)" :server="$filters.getServer(model)" v-model:file="baja.f_finiquito_firmado"></Uploader>
            </div>
          </div>
          <div class="col-sm-6 mt-3">
            <div class="form-group">
              <label class="form-label">Observaciones</label>
              <textarea v-model.lazy="baja.observaciones" class="form-control"></textarea>
            </div>
          </div>
          <div class="col-sm-6 mt-3">
            <div class="form-group">
              <label class="form-label">Observaciones Internas</label>
              <textarea v-model.lazy="baja.observaciones_internas" class="form-control"></textarea>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-right" v-if="nueva_baja">
            <button type="submit" class="mt-2 btn btn-lg btn-danger">Dar de baja al empleado</button>
          </div>
        </div>
      </form>
    </div><!-- .nk-block -->
  </form>
</template>

<script>
import {useApi} from "@/use/useApi";
import {reactive, toRefs, defineComponent, computed, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import Swal from "sweetalert2";
import Uploader from "@/components/globales/Uploader";
export default defineComponent({
  name: "BajasEmpleado",
  props: ['empleado'],
  components: {
    Uploader
  },
  setup(params) {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();
    const route = useRoute();

    const model = computed(()=>{
      return params.empleado;
    });

    const id_empleado = (model.value.id_empleado) ? model.value.id_empleado : route.params.id_empleado;

    const nueva_baja = computed(() => {
      return model.value.estatus !== 'Baja' && route.query.baja === 'si';
    });

    const state = reactive({
      inited: false,
      baja: {
        motivo: '',
        fecha: null,
        observaciones: '',
        observaciones_internas: '',
        id_empleado: 0,
        f_finiquito: {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        },
        f_finiquito_firmado: {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        },
      },
      bajas: []
    });

    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/bajas/get', {id_empleado});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          state.bajas = data.bajas;
          if(data.baja){
            state.baja = data.baja;
          }
        }
      }
    }
    get();

    async function newBaja() {
      loading.message = 'Actualizando información';
      //Save
      state.baja.id_empleado = id_empleado;
      let {data} = await make('empleados/bajas/new', {model: state.baja});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          Swal.fire('Éxito', 'El empleado ha sido de baja correctamente', 'success').then(() => {
            router.push('/sistema/empleados');
          });
        }
      }
    }

    async function save(){
      loading.message = 'Actualizando información';
      //Save
      state.baja.id_empleado = id_empleado;
      let {data} = await make('empleados/bajas/edit', {model: state.baja});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          state.inited = false;
          get();
        }
      }
    }

    async function remove(){
      Swal.fire({
        title: 'Eliminar empleado',
        text: `¿Estás seguro que deseas eliminar definitivamente a ${model.value.nombre} ${model.value.apellido_paterno} ${model.value.apellido_materno}?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#e85347',
        focusCancel: true
      }).then(async res => {
        if(res.isConfirmed){
          loading.message = 'Eliminando usuario';
          let {data} = await make('empleados/bajas/delete', {id_empleado});
          if(data === null){
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if(data.error){
              Swal.fire('Error', data.message, 'error');
            } else {
              Swal.fire('Éxito', 'El empleado ha sido de baja correctamente', 'success').then(() => {
                router.replace('/sistema/empleados');
              });
            }
          }
        }
      });
    }

    watch(() => state.baja, () => {
      if(state.inited && !nueva_baja.value){
        save();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    return {model, ...toRefs(state), loading, save, get, newBaja, remove, nueva_baja};
  }
});
</script>

<style scoped>
  textarea.form-control {
    min-height: 75px;
  }
  .form-label {
    text-transform: uppercase;
  }
</style>