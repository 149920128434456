<template>
  <form @submit.prevent="save">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Información Médica</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div class="row">
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Tipo de sangre</label>
            <input type="text" class="form-control" v-model.lazy="data.tipo_sangre" :disabled="isCliente">
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Enfermedades Crónicas</label>
            <textarea class="form-control" v-model.lazy="data.enfermedades_cronicas" maxlength="1000" :disabled="isCliente"></textarea>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Peso</label>
            <input type="text" class="form-control" v-model.lazy="data.peso" :disabled="isCliente">
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Estatura</label>
            <input type="text" class="form-control" v-model.lazy="data.estatura" :disabled="isCliente">
          </div>
        </div>
        <div class="col-sm-12 mb-3">
          <div class="form-group">
            <h4>IMC: {{IMC}}</h4>
          </div>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th width="50%">IMC</th>
                <th width="50%">Nivel de peso</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="IMC < 18.5" class="b-orange">
                <td>Por debajo de 18.5</td>
                <td>Bajo peso</td>
              </tr>
              <tr v-else>
                <td>Por debajo de 18.5</td>
                <td>Bajo peso</td>
              </tr>

              <tr v-if="IMC > 18.5 && IMC < 25" class="b-green">
                <td>18.5 – 24.9</td>
                <td>Normal</td>
              </tr>
              <tr v-else>
                <td>18.5 – 24.9</td>
                <td>Normal</td>
              </tr>

              <tr v-if="IMC >= 25 && IMC < 29.9" class="b-orange">
                <td>25.0 – 29.9</td>
                <td>Sobrepeso</td>
              </tr>
              <tr v-else>
                <td>25.0 – 29.9</td>
                <td>Sobrepeso</td>
              </tr>

              <tr v-if="IMC >= 30" class="b-red">
                <td>30.0 o más</td>
                <td>Obesidad</td>
              </tr>
              <tr v-else>
                <td>30.0 o más</td>
                <td>Obesidad</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div><!-- .nk-block -->
  </form>
  <form @submit.prevent="saveAntidopings" class="mt-3">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Antidopings</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div v-if="!antidopings.length" class="alert alert-info">
        <p>Aún no se ha añadido ningún certificado, da clic en <b>Agregar Certificado</b> para añadir uno.</p>
      </div>
      <div v-for="antidopoing in antidopings" :key="antidopoing.id_antidopoing" class="row">
        <div class="col-12 d-flex align-items-end justify-content-end">
          <button v-if="!isCliente" type="button" class="btn btn-outline-danger" @click.prevent="removeAntidopings(antidopoing)">Eliminar registro</button>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control" v-model.lazy="antidopoing.nombre" :disabled="isCliente">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Fecha</label>
            <input type="date" class="form-control" v-model.lazy="antidopoing.fecha" :disabled="isCliente">
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">Comprobante</label>
            <Uploader :preFiles="$filters.getFiles(antidopoing.f_certificado?.id_archivo)" :server="$filters.getServer(model)" v-model:file="antidopoing.f_certificado"></Uploader>
          </div>
        </div>
      </div>
      <div class="w-100 text-right">
        <button v-if="!isCliente" type="button" class="mt-2 btn btn-lg btn-info" @click.prevent="addAntidopings">Agregar Certificado</button>
      </div>
    </div><!-- .nk-block -->
  </form>
  <form @submit.prevent="saveCertificados" class="mt-3">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Certificados Médicos</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div v-if="!certificados.length" class="alert alert-info">
        <p>Aún no se ha añadido ningún certificado, da clic en <b>Agregar Certificado</b> para añadir uno.</p>
      </div>
      <div v-for="certificado in certificados" :key="certificado.id_certificado" class="row">
        <div class="col-12 d-flex align-items-end justify-content-end">
          <button v-if="!isCliente" type="button" class="btn btn-outline-danger" @click.prevent="removeCertificados(certificado)">Eliminar registro</button>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control" v-model.lazy="certificado.nombre" :disabled="isCliente">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Fecha</label>
            <input type="date" class="form-control" v-model.lazy="certificado.fecha" :disabled="isCliente">
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">Comprobante</label>
            <Uploader :preFiles="$filters.getFiles(certificado.f_certificado?.id_archivo)" :server="$filters.getServer(model)" v-model:file="certificado.f_certificado"></Uploader>
          </div>
        </div>
      </div>
      <div class="w-100 text-right">
        <button v-if="!isCliente" type="button" class="mt-2 btn btn-lg btn-info" @click.prevent="addCertificados">Agregar Certificado</button>
      </div>
    </div><!-- .nk-block -->
  </form>
</template>

<script>
import {useApi} from "@/use/useApi";
import {reactive, toRefs, defineComponent, computed, watch} from 'vue';
import { useRouter } from 'vue-router';
import Swal from "sweetalert2";
import Uploader from "@/components/globales/Uploader";
const { v4: uuidv4 } = require('uuid');
import useAuth from "@/providers/auth";

export default defineComponent({
  name: "MedicoEmpleado",
  components: {Uploader},
  props: ['empleado'],
  setup(params) {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();
    const { isCliente } = useAuth();

    const model = computed(()=>{
      return params.empleado;
    });

    const state = reactive({
      data: {
        id_empleado: 0,
        tipo_sangre: '',
        enfermedades_cronicas: '',
        peso: '',
        estatura: ''
      },
      IMC: 0,
      antidopings: [],
      certificados: [],
      inited: false
    });

    function computeIMC(peso, estatura) {
      return (peso / (Math.pow((estatura > 100 ? (estatura / 100) : estatura), 2))).toFixed(1);
    }

    //Methods
    async function getAntidopings(){
      let {data} = await make('empleados/medico/get-antidopings', {id_empleado: model.value.id_empleado});
      if(data === null){
        router.go();
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.go();
          Swal.fire('Error', data.message, 'error');
        } else {
          state.antidopings = data.antidopings;
        }
      }
    }

    async function getCertificados(){
      let {data} = await make('empleados/medico/get-certificados', {id_empleado: model.value.id_empleado});
      if(data === null){
        router.go();
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.go();
          Swal.fire('Error', data.message, 'error');
        } else {
          state.certificados = data.certificados;
        }
      }
    }

    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/medico/get', {id_empleado: model.value.id_empleado});
      if(data === null){
        router.go();
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.go();
          Swal.fire('Error', data.message, 'error');
        } else {
          state.data = data.model;

          if (data.model.peso > 0 && data.model.estatura > 0) {
            state.IMC = computeIMC(data.model.peso, data.model.estatura);
          }
          
          getAntidopings();
          getCertificados();
        }
      }
    }

    async function save(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/medico/edit', {model: state.data});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          if (state.data.peso > 0 && state.data.estatura > 0) {

            state.IMC = computeIMC(state.data.peso, state.data.estatura);
          }
        }
      }
    }

    watch(() => state.data, () => {
      if(state.inited){
        save();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    async function saveAntidopings(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/medico/edit-antidopings', {model: state.antidopings, id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        }
      }
    }

    function addAntidopings() {
      state.antidopings.push({
        id_antidoping: uuidv4(),
        nombre: '',
        fecha: null,
        f_certificado: {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        }
      });
    }

    function removeAntidopings(antidoping){
      state.antidopings = state.antidopings.filter(_antidoping => _antidoping.id_antidoping !== antidoping.id_antidoping);
    }

    watch(() => state.antidopings, () => {
      if(state.inited){
        saveAntidopings();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    async function saveCertificados(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/medico/edit-certificados', {model: state.certificados, id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        }
      }
    }

    function addCertificados() {
      state.certificados.push({
        id_certificado: uuidv4(),
        nombre: '',
        fecha: null,
        f_certificado: {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        }
      });
    }

    function removeCertificados(certificado){
      state.certificados = state.certificados.filter(_certificado => _certificado.id_certificado !== certificado.id_certificado);
    }

    watch(() => state.certificados, () => {
      if(state.inited){
        saveCertificados();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    get();

    return {model, ...toRefs(state), loading, save, saveAntidopings, addAntidopings, removeAntidopings, saveCertificados, addCertificados, removeCertificados, isCliente};
  }
});
</script>

<style scoped>
  textarea.form-control {
    min-height: 75px;
  }
  .form-label {
    text-transform: uppercase;
  }

  .table{width:100%;max-width:100%;margin-bottom:1rem;background-color:transparent}
.table th,.table td{padding:.75rem;vertical-align:top;border-top:1px solid #dee2e6}
.table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}
.table tbody + tbody{border-top:2px solid #dee2e6}
.table .table{background-color:#fff}
.table-sm th,.table-sm td{padding:.3rem}
.table-bordered{border:1px solid #dee2e6}
.table-bordered th,.table-bordered td{border:1px solid #dee2e6}
.table-bordered thead th,.table-bordered thead td{border-bottom-width:2px}
.table-striped tbody tr:nth-of-type(odd){background-color:rgba(0,0,0,0.05)}
.table-hover tbody tr:hover{background-color:rgba(0,0,0,0.075)}
.table-primary,.table-primary > th,.table-primary > td{background-color:#b8daff}
.table-hover .table-primary:hover{background-color:#9fcdff}
.table-hover .table-primary:hover > td,.table-hover .table-primary:hover > th{background-color:#9fcdff}
.table-secondary,.table-secondary > th,.table-secondary > td{background-color:#d6d8db}
.table-hover .table-secondary:hover{background-color:#c8cbcf}
.table-hover .table-secondary:hover > td,.table-hover .table-secondary:hover > th{background-color:#c8cbcf}
.table-success,.table-success > th,.table-success > td{background-color:#c3e6cb}
.table-hover .table-success:hover{background-color:#b1dfbb}
.table-hover .table-success:hover > td,.table-hover .table-success:hover > th{background-color:#b1dfbb}
.table-info,.table-info > th,.table-info > td{background-color:#bee5eb}
.table-hover .table-info:hover{background-color:#abdde5}
.table-hover .table-info:hover > td,.table-hover .table-info:hover > th{background-color:#abdde5}
.table-warning,.table-warning > th,.table-warning > td{background-color:#ffeeba}
.table-hover .table-warning:hover{background-color:#ffe8a1}
.table-hover .table-warning:hover > td,.table-hover .table-warning:hover > th{background-color:#ffe8a1}
.table-danger,.table-danger > th,.table-danger > td{background-color:#f5c6cb}
.table-hover .table-danger:hover{background-color:#f1b0b7}
.table-hover .table-danger:hover > td,.table-hover .table-danger:hover > th{background-color:#f1b0b7}
.table-light,.table-light > th,.table-light > td{background-color:#fdfdfe}
.table-hover .table-light:hover{background-color:#ececf6}
.table-hover .table-light:hover > td,.table-hover .table-light:hover > th{background-color:#ececf6}
.table-dark,.table-dark > th,.table-dark > td{background-color:#c6c8ca}
.table-hover .table-dark:hover{background-color:#b9bbbe}
.table-hover .table-dark:hover > td,.table-hover .table-dark:hover > th{background-color:#b9bbbe}
.table-active,.table-active > th,.table-active > td{background-color:rgba(0,0,0,0.075)}
.table-hover .table-active:hover{background-color:rgba(0,0,0,0.075)}
.table-hover .table-active:hover > td,.table-hover .table-active:hover > th{background-color:rgba(0,0,0,0.075)}
.table .thead-dark th{color:#fff;background-color:#212529;border-color:#32383e}
.table .thead-light th{color:#495057;background-color:#e9ecef;border-color:#dee2e6}
.table-dark{color:#fff;background-color:#212529}
.table-dark th,.table-dark td,.table-dark thead th{border-color:#32383e}
.table-dark.table-bordered{border:0}
.table-dark.table-striped tbody tr:nth-of-type(odd){background-color:rgba(255,255,255,0.05)}
.table-dark.table-hover tbody tr:hover{background-color:rgba(255,255,255,0.075)}
@media (max-width: 575.98px) {
.table-responsive-sm{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
.table-responsive-sm > .table-bordered{border:0}
}
@media (max-width: 767.98px) {
.table-responsive-md{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
.table-responsive-md > .table-bordered{border:0}
}
@media (max-width: 991.98px) {
.table-responsive-lg{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
.table-responsive-lg > .table-bordered{border:0}
}
@media (max-width: 1199.98px) {
.table-responsive-xl{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
.table-responsive-xl > .table-bordered{border:0}
}
.table-responsive{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
.table-responsive > .table-bordered{border:0}
.b-green{background: green; color: white;}
.b-orange{background: orange; color: white;}
.b-red{background: red; color: white;}
</style>